import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import Image from 'next/image';
import ads from '../public/assets/icons/ads.png';
import { useRouter } from 'next/router';

const Advertisement = () => {
  const router = useRouter();
  const handleClick = () => {
    router.push('/roadmaps'); // Redirects to the /roadmaps page
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: { xs: 'center', sm: 'center' },
        justifyContent: { xs: 'center', sm: 'space-between' },
        padding: '16px',
        border: '1px solid #E0E0E0',
        borderRadius: '8px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.05)',
        backgroundColor: '#fff',
        marginBottom: '16px',
        marginTop: "-30px",
        flexDirection: { xs: 'column', sm: 'row' },
        textAlign: { xs: 'center', sm: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: { xs: 'column', sm: 'row' },
          marginBottom: { xs: '16px', sm: '0' },
          textAlign: { xs: 'center', sm: 'left' },
        }}
      >
        <Image
          src={ads}
          alt="Live Icon"
          width={60}
          height={60}
          style={{ maxWidth: '100%', height: 'auto' }}
        />
        <Box sx={{ marginLeft: { xs: '0', sm: '16px' }, marginTop: { xs: '8px', sm: '0' } }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: '16px', sm: '18px' },
            }}
          >
            Join our free LIVE classes and get certificates after exam
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: '#555',
              fontSize: { xs: '14px', sm: '16px' },
              marginTop: { xs: '8px', sm: '4px' },
            }}
          >
            Explore & Learn, build resumes, apply for jobs.
          </Typography>
        </Box>
      </Box>
      <Button
        variant="contained"
        sx={{
          textTransform: 'none',
          fontWeight: 'medium',
          backgroundColor: '#427BF1',
          padding: { xs: '8px 16px', sm: '5px 20px' },
          fontSize: { xs: '14px', sm: '16px' },
        }}
        onClick={handleClick}
      >
        Explore Roadmaps
      </Button>
    </Box>
  );
};

export default Advertisement;
