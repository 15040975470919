import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  IconButton,
  Box
} from '@mui/material';
import {
  Dashboard,
  School,
  Flag,
  EventAvailable,
  TrendingUp,
  Work,
  AccountBalance,
  Code,
  BusinessCenter,
  ChevronLeft,
  ChevronRight,
} from '@mui/icons-material';
import Link from 'next/link';
import Image from 'next/image';
import airedify from "../../../../../apps/personal-portfolio/public/assets/logo/logo.png"; 

const drawerWidth = 240; // Full width of the Drawer when expanded
const collapsedWidth = 60; // Width when the Drawer is collapsed

const menuItems = [
  { label: 'Dashboard', icon: <Dashboard />, link: '/dashboard' },
  { label: 'My Certificates', icon: <School />, link: '/mycertificate' },
  { label: 'Roadmaps', icon: <Flag />, link: '/roadmaps' },
  { label: 'My Classes', icon: <EventAvailable />, link: '#' },
];

const exploreItems = [
  { label: 'Interviews', icon: <Work /> },
  { label: 'LifeStyle', icon: <TrendingUp /> },
  { label: 'Productivity', icon: <BusinessCenter /> },
];

const otherProducts = [
  { label: 'AirExam', icon: <School /> },
  { label: 'AirList', icon: <AccountBalance /> },
  { label: 'AirFinance', icon: <TrendingUp /> },
  { label: 'AirCode', icon: <Code /> },
];

const SideMenu = ({ onToggleCollapse }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
    onToggleCollapse(!isCollapsed); // Notify parent component of the collapse state
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: isCollapsed ? collapsedWidth : drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isCollapsed ? collapsedWidth : drawerWidth,
          boxSizing: 'border-box',
          overflowX: 'hidden',
          transition: 'width 0.3s',
        },
        display: { xs: 'none', sm: 'block' }, // Hide Drawer on mobile view
      }}
      open
    >
      <List
        style={{
          marginTop: '10px',
          paddingLeft: isCollapsed ? '8px' : '30px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: isCollapsed ? 'center' : 'flex-start',
        }}
      >
        {/* Container for arrow and logo */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: isCollapsed ? 'center' : 'space-between',
            width: '100%',
            padding: '0 10px',
            marginBottom: '10px',
          }}
        >
          {/* Arrow to toggle the sidebar */}
          <IconButton onClick={toggleCollapse}>
            {isCollapsed ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>

          {/* Logo */}
          {!isCollapsed && (
            <Link href="/" className="logo">
              <Image
                src={airedify}
                alt="Logo"
                style={{
                  height: '55px',
                  transition: 'display 0.3s',
                }}
              />
            </Link>
          )}
        </Box>

        {/* Main Menu Items */}
        {menuItems.map((item, index) => (
          <React.Fragment key={index}>
            <Link href={item.link} passHref>
              <ListItem
                button
                sx={{
                  paddingLeft: isCollapsed ? '8px' : '16px', // Adjusts padding based on collapsed state
                  '&:hover': { backgroundColor: '#f5f5f5' },
                  justifyContent: isCollapsed ? 'center' : 'flex-start',
                  color: item.link !== '#' ? 'black' : '#999', // Black for redirecting items, dull color for inactive items
                }}
              >
                <ListItemIcon sx={{ minWidth: '40px', color: item.link !== '#' ? 'black' : '#999' }}>
                  {item.icon}
                </ListItemIcon>
                {!isCollapsed && (
                  <ListItemText
                    primary={item.label}
                    sx={{ fontWeight: 'medium' }} // Set font weight to medium
                  />
                )}
              </ListItem>
            </Link>
            {index === 3 && <Divider sx={{ backgroundColor: '#E0E0E0', width: '100%' }} />}
          </React.Fragment>
        ))}

        {/* Explore Section */}
        <Divider sx={{ backgroundColor: '#E0E0E0', width: '100%' }} />
        {!isCollapsed && (
          <Typography
            sx={{
              margin: '10px 16px',
              fontWeight: 'bold',
              fontSize: '14px',
              color: '#555',
            }}
          >
            Explore
          </Typography>
        )}
        {exploreItems.map((item, index) => (
          <ListItem
            button
            key={index}
            sx={{
              paddingLeft: isCollapsed ? '8px' : '16px',
              '&:hover': { backgroundColor: '#f5f5f5' },
              justifyContent: isCollapsed ? 'center' : 'flex-start',
              color: '#999', // Dull color for inactive items
            }}
          >
            <ListItemIcon sx={{ minWidth: '40px', color: '#999' }}>{item.icon}</ListItemIcon>
            {!isCollapsed && (
              <ListItemText
                primary={item.label}
                sx={{ fontWeight: 'medium' }} // Set font weight to medium
              />
            )}
          </ListItem>
        ))}

        {/* Other Products Section */}
        <Divider sx={{ backgroundColor: '#E0E0E0', width: '100%' }} />
        {!isCollapsed && (
          <Typography
            sx={{
              margin: '10px 16px',
              fontWeight: 'bold',
              fontSize: '14px',
              color: '#555',
            }}
          >
            Other Products
          </Typography>
        )}
        {otherProducts.map((item, index) => (
          <ListItem
            button
            key={index}
            sx={{
              paddingLeft: isCollapsed ? '8px' : '16px',
              '&:hover': { backgroundColor: '#f5f5f5' },
              justifyContent: isCollapsed ? 'center' : 'flex-start',
              color: '#999', // Dull color for inactive items
            }}
          >
            <ListItemIcon sx={{ minWidth: '40px', color: '#999' }}>{item.icon}</ListItemIcon>
            {!isCollapsed && (
              <ListItemText
                primary={item.label}
                sx={{ fontWeight: 'medium' }} // Set font weight to medium
              />
            )}
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default SideMenu;
