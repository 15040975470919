import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Skeleton, Box, Typography } from '@mui/material';
import Image from 'next/image';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Events from '../public/assets/icons/events.png';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useUser } from '@clerk/nextjs'; // Import useUser hook from Clerk

const EventComponent = ({ loading, eventsReccomendation, icons, router }) => {
  const { isLoaded, user } = useUser(); // Retrieve user data using useUser hook
  const [userName, setUserName] = useState('');

  useEffect(() => {
    if (isLoaded && user) {
      setUserName(user?.firstName || ''); // Set the user's first name
    }
  }, [isLoaded, user]);

  return (
    <section
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        marginBottom: '20px',
        
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <Image
          src={Events}
          alt="Recommendation Icon"
          width={44}
          height={44}
          style={{ display: 'inline-block', verticalAlign: 'middle' }}
        />
        <h4 style={{ margin: 0, fontWeight: '600', fontSize: '18px' }}>
          {/* Display the user's name here */}
          {userName && `${userName},`} Exciting Events & Competitions are happening ! Apply Fast 🚀
        </h4>
      </div>
      <div
        style={{
          borderBottom: '2px dotted #cccccc', // Dotted line style
          marginTop: '5px',
          marginBottom: '10px',
        }}
      ></div>
      <section
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          marginBottom: '15px',
        }}
      >
        {loading ? (
          <Grid container spacing={2}>
            {Array.from(new Array(3)).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card sx={{ borderRadius: 6 }}>
                  <CardContent style={{ minHeight: '250px', minWidth: '370px' }}>
                    <Skeleton variant="rectangular" width="100%" height={150} sx={{ marginBottom: 2 }} />
                    <Skeleton variant="text" width="60%" height={30} />
                    <Skeleton variant="text" width="80%" height={20} />
                    <Skeleton variant="text" width="40%" height={20} />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container spacing={2}>
            {eventsReccomendation &&
              Boolean(eventsReccomendation.length) &&
              eventsReccomendation.map((eventpost) => {
                const icon = {
                  icon: icons[eventpost.event_type] || AccountCircleIcon,
                };

                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={eventpost.id}
                    onClick={() => {
                      router.push(`/event/${eventpost.id}`);
                    }}
                  >
                    <Card sx={{ borderRadius: 6, boxShadow: 0 }}>
                      <CardContent
                        className="identify-this"
                        style={{
                          minHeight: '250px',
                          minWidth: '370px',
                        }}
                      >
                        <Box display="flex" alignItems="center" mb={2}>
                        <Image 

src={`${eventpost.icon_url}`}
width={40}
height={40}
alt={`${eventpost.event_name}`}
style={{margin : "10px" , borderRadius : "50%"}}
/>
                          <Box>
                            <Typography variant="h6" component="div" fontWeight="bold">
                              {eventpost.event_name}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ borderBottom: '1px dotted grey', mb: 2 }} />
                        <Box display="flex" alignItems="center" mb={1}>
                          <ListAltIcon sx={{ color: 'text.secondary', mr: 1 }} />
                          <Typography variant="body2" color="text.secondary" fontWeight="bold">
                            {eventpost.event_mode?.toLowerCase() === 'online'
                              ? 'ONLINE'
                              : eventpost.event_location}{' '}
                            | {eventpost.event_theme}
                          </Typography>
                        </Box>
                        <Typography
                          variant="body2"
                          mt={1}
                          sx={{
                            backgroundColor: '#F2F9FF',
                            color: '#006DDD',
                            fontWeight: 'bold',
                            padding: '5px 10px',
                            borderRadius: '10px',
                          }}
                        >
                          Prize Pool of {eventpost.prize_money}
                        </Typography>
                        <Box display="flex" justifyContent="space-between" mt={1}>
                          <Typography variant="body2" color="text.secondary" sx={{ color: '#00BF36' }}>
                            {eventpost.registration_fee}
                          </Typography>
                          <Box display="flex" alignItems="center">
                            <CalendarTodayIcon sx={{ color: 'text.secondary', mr: 1 }} />
                            <Typography variant="body2" color="text.secondary">
                              {eventpost.date_of_event}
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        )}
      </section>
    </section>
  );
};

export default EventComponent;
