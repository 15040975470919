import React from 'react';
import Link from 'next/link';
import { useTheme } from 'next-themes';
import { Box, Grid } from '@mui/material';

const AfterJobRow = () => {
  const { theme } = useTheme();

  const en = {
    landing: {
      blog: {
        media: '/assets/blog.png',
        caption: 'Read Articles',
        heading: 'Study Materials',
        button: {
          link: '/posts',
          icon: {
            light: '/assets/icons/icon-2.svg',
            dark: '/assets/icons/icon.svg',
          },
        },
      },
      offerings: {
        heading: 'Offerings',
        caption: 'Explore offerings',
        link: '/offerings',
        icons: [
          'iconoir-figma',
          'iconoir-input-output',
          'iconoir-design-pencil',
          'iconoir-code',
        ],
        button: {
          link: '/offerings',
          icon: {
            light: '/assets/icons/icon-2.svg',
            dark: '/assets/icons/icon.svg',
          },
        },
      },
      profiles: {
        heading: 'Featuring',
        caption: 'Explore',
        media: null,
        button: {
          link: '/contact',
          icon: {
            light: '/assets/icons/icon-2.svg',
            dark: '/assets/icons/icon.svg',
          },
        },
        profiles: [
          {
            link: 'https://sponsorgenix.in/',
            icon: '/assets/sponsorgenix.png',
          },
          {
            link: 'https://gearo.tech/',
            icon: '/assets/gearo.png',
          },
        ],
      },
    },
  };

  return (
    <Box sx={{ mt: 6, px: { xs: 2, md: 0 } }}>
      <Grid container spacing={2} direction={{ xs: 'column', md: 'row' }}>
        <Grid item xs={12} md={3} data-aos="zoom-in">
          <Box
            className="info-box shadow-box"
            sx={{
              position: 'relative',
              height: { xs: 'auto', md: '300px' },
            }}
          >
            <Link href="/posts">
              <img
                src="/assets/bg1.png"
                alt="BG"
                className="bg-img"
                style={{ width: '100%', height: 'auto' }}
              />
              <img
                src={en.landing.blog.media}
                alt="thumbnail"
                style={{ width: '80%', height: 'auto' }}
              />
              <Box
                className="d-flex align-items-center justify-content-between"
                sx={{ mt: 2, p: 2 }}
              >
                <Box className="infos">
                  <b>{en.landing.blog.caption}</b>
                  <h1>{en.landing.blog.heading}</h1>
                </Box>
                <img
                  src={
                    theme === 'dark'
                      ? en.landing.blog.button.icon.dark
                      : en.landing.blog.button.icon.light
                  }
                  alt="button"
                  className="about-btn"
                />
              </Box>
            </Link>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} data-aos="zoom-in">
          <Box
            className="info-box shadow-box"
            sx={{
              paddingTop:"60px",
              height: { xs: 'auto', md: '300px' }, 
            }}
          >
            <Link href={en.landing.offerings.link}>
              <img
                src="/assets/bg1.png"
                alt="BG"
                className="bg-img"
                style={{ width: '100%', height: 'auto' }}
              />
              <Box
                className="icon-boxes"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  p: 2,
                  gap: 1,
                }}
              >
                {en.landing.offerings.icons.map((item, index) => (
                  <i
                    key={index}
                    className={item}
                    style={{ fontSize: '2rem' }}
                  />
                ))}
              </Box>

              <Box
                className="d-flex align-items-center justify-content-between"
                sx={{ mt: 2, p: 2 }}
              >
                <Box className="infos">
                  <b>{en.landing.offerings.caption}</b>
                  <h1>{en.landing.offerings.heading}</h1>
                </Box>
                <img
                  src={
                    theme === 'dark'
                      ? en.landing.offerings.button.icon.dark
                      : en.landing.offerings.button.icon.light
                  }
                  alt="Button"
                  className="about-btn"
                />
              </Box>
            </Link>
          </Box>
        </Grid>

        <Grid item xs={12} md={3} data-aos="zoom-in">
          <Box
            className="info-box shadow-box"
            sx={{
              position: 'relative',
              height: { xs: 'auto', md: '300px' }, // Adjust height here
            }}
          >
            <img
              src="/assets/bg1.png"
              alt="BG"
              className="bg-img"
              style={{ width: '100%', height: 'auto' }}
            />
            {Array.from(
              {
                length: Math.ceil(en.landing.profiles.profiles.length / 2),
              },
              (_, i) => i * 2
            ).map((startIndex, index) => (
              <Box
                className="inner-profile-icons shadow-box"
                key={index}
                sx={{ display: 'flex', justifyContent: 'space-around', p: 2 }}
              >
                {en.landing.profiles.profiles
                  .slice(startIndex, startIndex + 2)
                  .map((item, i) => (
                    <Link href={item.link} key={i}>
                      <img
                        src={item.icon}
                        alt="thumbnail"
                        style={{ width: '50%', height: 'auto' }}
                      />
                    </Link>
                  ))}
              </Box>
            ))}
            <Box
              className="d-flex align-items-center justify-content-between"
              sx={{ mt: 2, p: 2 }}
            >
              <Box className="infos">
                <b>{en.landing.profiles.caption}</b>
                <h1>{en.landing.profiles.heading}</h1>
              </Box>
              <Link href={en.landing.profiles.button.link}>
                <img
                  src={
                    theme === 'dark'
                      ? en.landing.profiles.button.icon.dark
                      : en.landing.profiles.button.icon.light
                  }
                  alt="Button"
                  className="about-btn"
                />
              </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AfterJobRow;
