import { Layout, getThemedContent } from '@msanvarov/core-components';
import { en } from '@msanvarov/i18n';
import { useTheme } from 'next-themes';
import Link from 'next/link';
import { useState, useEffect } from 'react';

import { useRouter } from 'next/navigation';
import Loader from './Loader';
import dataFetch from '../utils/dataFetch';
import Popup from './popup';

import { icons } from './jobs';

import { useAuth, useUser } from '@clerk/nextjs';
import { setEvents, setJobs, setUser, useAppDispatch } from '@msanvarov/store';

import animationData from '../public/assets/lottie/airedify-lotti.json';

import EventComponent from './EventComponent';
import IntroComponent from './IntroComponent';
import JobComponent from './JobComponent';
import YouTubeVideos from './YouTubeVideos';
import MasterclassComponent from './MasterclassComponent';
import LastRow from './LastRow';
import AfterJobRow from './AfterJobRow';
import { Box, Grid, Snackbar, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import SideMenu from 'libs/core-components/src/lib/layout/SideMenu';

import styled from 'styled-components';
import Advertisement from './advertisement';

// Define the styled component with media queries
// @ts-ignore

interface AboutAreaProps {
  isSidebarCollapsed: boolean;
}
// Define the styled component with media queries
const AboutArea = styled.section<AboutAreaProps>`
  margin-left: ${(props) => (props.isSidebarCollapsed ? '0px' : '200px')};
  transition: margin-left 0.3s; /* Optional for smooth transitions */

  @media (max-width: 600px) {
    margin-left: 0px;
  }
`;


const videos = [
  'https://www.youtube.com/embed/fOpLQ-wXH4M?start=1',
  'https://www.youtube.com/embed/Lg0ZDl7V7Vk?start=131',
  'https://www.youtube.com/embed/l6UD7P1mWSU?start=16',
];
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const LandingPage = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  // const handleSidebarToggle = () => {
  //   setIsSidebarCollapsed(prevState => !prevState);
  // };

  const { theme } = useTheme();
  const [showPopup, setShowPopup] = useState(true);
  const [isAllowed, setAllowed] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [isUserAuthenticated, SetUserAuthenticated] = useState(false);
  const [openToast, setOpenToast] = useState(true);

  const router = useRouter();
  const [isSplashScreenVisible, setSplashScreenVisible] = useState(true);

  const { isLoaded, isSignedIn, user } = useUser();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [jobsReccomendation, setJobsReccomendation] = useState([]);
  const [eventsReccomendation, setEventsReccomendation] = useState([]);
// @ts-ignore
  const handleSidebarToggle = (collapsed) => {
    setIsSidebarCollapsed(collapsed);
  };


  console.log(user , "this is the user");

  useEffect(() => {
    if (user) {
      dispatch(setUser(user));
    }

    if(user?.emailAddresses[0].emailAddress){

      console.log("user signed up");
      setShowSignup(true)
      dispatch(setUser(user));
    }
  } , [user]); 


  useEffect(() => {
    setTimeout(() => {
        setShowSignup(true);      
    } , 3000);
  } , []);

  const setSignUpShow = () => {
    // const objectInfo = document
    //   .getElementById('sign-up-show')
    //   ?.getBoundingClientRect();

    // const viewPortHeight =
    //   document.documentElement.clientHeight || window.innerHeight;

    // // @ts-ignore
    // if (viewPortHeight - objectInfo?.top > 0) {
    //   setShowSignup(true);
    // }

    setTimeout(() => {
        setShowSignup(true);
    } , 4000);
  };


  if (isLoaded && isSplashScreenVisible) {
    setSplashScreenVisible(false);
  }

  if (isSignedIn && !isAllowed && showPopup) {
    console.log(isAllowed, 'isallowed');
    setAllowed(true);
    setShowPopup(false);
  }

  useEffect(() => {
    const sheetname = 'jobs';
    const data = dataFetch(sheetname, null);

    data.then((res) => {
      if (res?.data) {
        dispatch(setJobs(res.data));
      }

      //create a reccomendations array

      if (res?.data) {
        const temp = res.data;

        if (temp.length > 3) {
          setJobsReccomendation(
            res.data.slice(res.data.length - 3, res.data.length - 1).reverse()
          );
        } else {
          setJobsReccomendation(res.data.reverse());
        }
      }

      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const sheetname = 'events';
    const data = dataFetch(sheetname, null);

    data.then((res) => {
      if (res?.data) {
        dispatch(setEvents(res.data));
      }

      //create a reccomendations array
      if (res?.data) {
        const temp = res.data;

        if (temp.length > 3) {
          setEventsReccomendation(
            res.data?.slice(res.data.length - 3, res.data.length - 1)?.reverse()
          );
        } else if (temp.length > 0) {
          console.log(res.data);
          setEventsReccomendation(res.data);
        }
      }

      setLoading(false);
    });
  }, []);
  // @ts-ignore
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const handleStartClick = () => {
    window.open('https://chat.whatsapp.com/BtBjxESWWUG5BEFwaofIgz', '_blank');
  };

  if (isSplashScreenVisible) {
    return <Loader />; // Show Loader component
  }

  return (
    <Layout setShowSignup={setShowSignup} isSidebarCollapsed={isSidebarCollapsed}>
      <SideMenu onToggleCollapse={handleSidebarToggle} />
      {showPopup && showSignup && <Popup onClose={() => setShowPopup(false)} />}
      {/* // @ts-ignore */}
      <AboutArea isSidebarCollapsed={isSidebarCollapsed} className="about-area">
        <Box
          sx={{
            width: '100%',
            padding: { xs: 2, sm: 3, md: 4 },
            px: { xs: 2, sm: 3, md: 10 },
            boxSizing: 'border-box',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {/* Events component   */}
              <Advertisement />
              <EventComponent
                loading={loading}
                eventsReccomendation={eventsReccomendation}
                icons={icons}
                router={router}
              />
              {/* Event component ends */}
            </Grid>

            <Grid item xs={12}>
              <IntroComponent
                // @ts-ignore
                animationData={animationData}
                en={en}
                theme={theme}
                getThemedContent={getThemedContent}
              />
            </Grid>

            <Grid item xs={12}>
              <JobComponent
                jobsReccomendation={jobsReccomendation}
                icons={icons}
              />
            </Grid>

            <Grid item xs={12} mt={4}>
              <AfterJobRow />
            </Grid>

            <Grid item xs={12} mt={4}>
              <MasterclassComponent />
            </Grid>

            <Grid item xs={12} mt={4}>
              <YouTubeVideos videos={videos} />
            </Grid>

            <Grid item xs={12} mt={4}>
              <LastRow />
            </Grid>
          </Grid>
        </Box>
        </AboutArea>
     
    </Layout>
  );
};

export default LandingPage;
